<template>
	<div class="mainTem">
		<!-- <div class="buyerHeader">
			<h3 class="buyerHeaderTit">
				{{ $t('Storage.nav.SKU_information') }}
				<el-tooltip effect="dark" :content="$t('btn.long_Export_excel')"ng_Export_excel')" placement="top">
					<el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcelAction()"></el-button>
				</el-tooltip>
			</h3>
		</div> -->
		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">SKU<span>{{$t('i18nn_f085596674018f3e')}}</span></span>
						<el-tooltip effect="dark" :content="$t('i18nn_ff6fd823ffab7e6b')" placement="top">
							<el-button type="warning" circle icon="el-icon-download" size="mini"
								@click="exportExcelAction()"></el-button>
						</el-tooltip>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<!-- <el-button type="primary" circle icon="el-icon-help" size="small" @click="initData()">
					</el-button> -->
					<!-- <ul class="actionConList"> -->
						<!-- <li> -->
							<el-button type="success" @click="openDioalog(null)" size="small" icon="el-icon-plus">{{$t('i18nn_786593149c0bf9f8')}}</el-button>
						<!-- </li> -->
						<!-- <li> -->
							<el-button type="danger" @click="delAction($event, null)" size="small" icon="el-icon-delete">{{$t('i18nn_e33c9b93c36fd250')}}</el-button>
						<!-- </li> -->
					<!-- </ul> -->
					
					<el-link type="primary" icon="el-icon-question" target="_blank" :href="'https://hytx-oss-wh.oss-cn-hongkong.aliyuncs.com/helpDoc/RelationSKu.pdf'" style="padding:0 10px">{{$t('i18nn_90aa9ed0f2de2aa5')}}</el-link>
					
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>
		<!-- <div class="formMsgCon">
        <h3><i class="titleIcon el-icon-bell"></i>{{$t('i18nn_41413cddfa61538c')}}</h3>
        <div class="formMsgDet">
          <p>
            此为录入仓库的SKU数据
          </p>
        </div>
    </div> -->

		<div class="filterCon" style="">
			<!-- <div class="filterBarList">
				<el-menu :default-active="filterData.status" mode="horizontal" @select="handleSelectStatus">
					<el-menu-item :index="item.value" v-for="item in selectOption.statusList" :key="item.value">
						<span>
							{{i18nFormatter(item.value)}}
						</span>
					</el-menu-item>
				</el-menu>
			</div> -->
			<div class="filterAction">
				<!-- <ul class="actionConList">
					<li>
						<el-button type="success" @click="openDioalog(null)" size="small" icon="el-icon-plus">{{$t('i18nn_786593149c0bf9f8')}}</el-button>
					</li>
					<li>
						<el-button type="danger" @click="delAction($event, null)" size="small" icon="el-icon-delete">{{$t('i18nn_e33c9b93c36fd250')}}</el-button>
					</li>
				</ul> -->
				<ul class="filterConList">
					<li>
						<span>{{ $t('i18nn_f77f473d27f48bdc') }}</span>
						<SelAgentUser @change="changeAgentUser"></SelAgentUser>
					</li>
					<li>
						<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
						<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword"
							maxlength="50" :clearable="true" style="width: 150px;" @keyup.enter.native="initData">
						</el-input>
					</li>
					<li>
						<el-button icon="el-icon-search" size="small" type="primary" @click="initData()"></el-button>
					</li>
					<!-- <li>
						<el-button icon="el-icon-s-operation" size="small" type="primary" @click="openMoreFilter()"></el-button>
					</li> -->
				</ul>
			</div>
		</div>

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%" size="small"
				@selection-change="handleSelectionChange" :max-height="$store.state.frameConHeightWh1"
				@sort-change="sortChange" :default-sort="{'prop':'goodsSku','order':'descending'}">
				<!-- <el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50"
					align="center" :label="$t('Storage.tableColumn.no')"></el-table-column> -->
				<el-table-column type="selection" fixed="left" align="center" width="50">
				</el-table-column>
				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50"
					align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>
				<!-- <el-table-column prop="statusName" :label="$t('i18nn_6cdece641436d7ab')">
					<template slot-scope="scope">
						<el-tag type="" v-if="'0' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}
						</el-tag>
						<el-tag type="success" v-else-if="'10' == scope.row.status">
							{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="warning" v-else-if="'20' == scope.row.status">
							{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="danger" v-else-if="'30'==scope.row.status">
							{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
					</template>
				</el-table-column> -->

				<el-table-column prop="goodsSku" label="SKU" :sortable="'custom'"></el-table-column>

				<el-table-column prop="relaGoodsSku" :label="$t('i18nn_9168144190f66f5d')">
					<template slot-scope="scope">
						<div>
							<div>{{scope.row.relaGoodsSku}}</div>
						</div>
					</template>
				</el-table-column>
				
				<el-table-column prop="goodsSku" :label="'SKU'" width="400px">
					<template slot-scope="scope">
						<barcode :code="scope.row.goodsSku"></barcode>
					</template>
				</el-table-column>
				
				<el-table-column prop="relaGoodsSku" :label="$t('i18nn_9168144190f66f5d')" width="400px">
					<template slot-scope="scope">
						<barcode :code="scope.row.relaGoodsSku"></barcode>
					</template>
				</el-table-column>


				<!-- <el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')" show-overflow-tooltip>
				</el-table-column> -->

				<el-table-column prop="createTime" :label="$t('i18nn_2594059036dfede5')" width="200">
					<template slot-scope="scope">
						<div>
							<div><span>{{$t('i18nn_e8564657bbe9ca53')}}</span>：{{scope.row.createTime}}</div>
							<div><span>{{$t('i18nn_e1911e9360047dcb')}}</span>：{{scope.row.updateTime}}</div>
						</div>
					</template>
				</el-table-column>





				<el-table-column :label="$t('Storage.tableColumn.operation')" width="200px" fixed="right">
					<template slot-scope="scope">

						<div>
							<!-- <el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini"
								icon="el-icon-edit">{{ $t('FormMsg.Edit') }}</el-button> -->
							<el-button @click="delAction($event, scope.row)" type="danger" size="mini"
								icon="el-icon-minus">{{ $t('FormMsg.Delete') }}
							</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>

			<div class="tableCon_pagination">
				<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
			</div>
		</div>

		<!-- 修改弹窗 -->
		<!-- <skuRelationAdd :openTime="addOpenTime" :row="addOpenRow" @submitSuccess="initData()"></skuRelationAdd> -->
		<!-- <el-dialog :close-on-click-modal="false" top="10px" :title="$t('Storage.skuInfo.Add_SKU')" append-to-body
			:visible.sync="dialogFormVisible" custom-class="myFullDialog4"> -->
		<!-- <el-drawer :wrapperClosable="false" :title="$t('Storage.skuInfo.Add_SKU')" append-to-body :visible.sync="dialogFormVisible"
			:direction="'rtl'" size="800px">


			<el-form ref="form" :rules="formRules" :size="'small'" :model="form" label-width="100px" style=""
				v-loading="loading" :element-loading-text="$t('i18nn_1b4525c800280581')">

				<el-form-item label="SKU" prop="goodsSku">
					<el-input type="text" v-model="form.goodsSku" :placeholder="$t('FormMsg.Please_Enter')">
					</el-input>
				</el-form-item>

				<el-form-item :label="$t('i18nn_9168144190f66f5d')" prop="relaGoodsSku">
					<el-input :placeholder="$t('FormMsg.Please_Enter')" v-model="form.relaGoodsSku">
					</el-input>
				</el-form-item>

			</el-form>
			<div class="drawer-footer">
				<el-button type="primary" @click="submitForm('form')">{{ $t('FormMsg.Save') }}</el-button>
			</div>
		</el-drawer> -->
		<!-- </el-dialog> -->


		<!-- excel 导出排序 -->
		<whExcelCustom :openTime="excelOpenTime" :excelHead="excelHead" :excelData="expExcelData"
			:pagination="pagination" :excelName="excelName" :excelOption="excelOption"
			:expHttpUrl="$urlConfig.WhSkuRelationPage" :expHttpFilter="pageFilterData()"></whExcelCustom>
	</div>
</template>
<script>
	// import {
	// 	WSkuInfo_status
	// } from '@/i18n/langStatus.js';
	// import {
	// 	i18nStatus
	// } from '@/i18n/FormatI18n.js';

	// import JQ from 'jquery';

	import {
		FormatTableSort
	} from '@/utils/utils.js';

	// import CommodityCateLinkage from '@/components/Common/CommodityCateLinkage.vue';
	// import excelUtilsNew from '@/utils/excelUtilsNew.js';
	// import barcode from '@/components/Common/barcode.vue';
	import SelAgentUser from '@/components/Common/SelAgentUser.vue';
	import whExcelCustom from '@/components/StorageCenter/components/whExcelCustom.vue';
	import barcode from '@/components/Common/barcode.vue';
	// import skuRelationAdd from '@/components/StorageCenter/WSkuProduct/WSkuRelation/skuRelationAdd.vue';
	// import uploadExcelBtn from '@/components/StorageCenter/components/uploadExcelBtn.vue';

	export default {
		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		//   meta: [{ // set meta
		//     name: '互易天下-买家中心-收货账单',
		//     content: '互易天下-厂家共享平台-买家中心-收货账单'
		//   }],
		//   // link: [{                 // set link
		//   //   rel: 'asstes',
		//   //   href: 'https://assets-cdn.github.com/'
		//   // }]
		// },
		// props: ['isDialog'],
		components: {
			// CommodityCateLinkage,
			barcode,
			SelAgentUser,
			whExcelCustom,
			// skuRelationAdd
			// uploadExcelBtn
		},
		// props: {
		// 	// mobile:"",
		// 	isSel: {
		// 		default: function() {
		// 			return false;
		// 		},
		// 		type: Boolean
		// 	},
		// 	status: {
		// 		default: function() {
		// 			return '';
		// 		},
		// 		type: String
		// 	},
		// },
		data() {
			return {
				//excel导出
				excelOpenTime: '',
				excelHead: [],
				expExcelData: [],
				excelName: '',
				excelOption: {},
				// activeName: 'second',
				// isShowFrom:false,
				// pickerOptions1: {
				//     disabledDate(time) {
				//       return time.getTime() > Date.now();
				//     },
				//     shortcuts: [{
				//       text: this.$t('i18nn_0200bd47bb4bb83d'),
				//       onClick(picker) {
				//         picker.$emit('pick', new Date());
				//       }
				//     }, {
				//       text: this.$t('i18nn_84b73bfc6dada445'),
				//       onClick(picker) {
				//         const date = new Date();
				//         date.setTime(date.getTime() - 3600 * 1000 * 24);
				//         picker.$emit('pick', date);
				//       }
				//     }, {
				//       text: this.$t('i18nn_e3f3138cf5ba3f6d'),
				//       onClick(picker) {
				//         const date = new Date();
				//         date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
				//         picker.$emit('pick', date);
				//       }
				//     }]
				//   },
				// UserInfo: this.$store.getters.getUserInfo,
				// dialogFormVisible: false,
				// dialogFormStatus: 0, //0-新增，1-修改

				// addOpenTime:"",
				// addOpenRow:{},
				//导入excel
				// dialogUploadVisible: false,
				// loadingExcel: false,
				// // fileExcel: '',
				// excelData: [],
				// // excelFileName: '',

				// openTimeUpExc:"",

				//图片放大
				// dialogImgVisible: false,
				// imgUrlBigShow: '',
				multipleSelection: [],
				// CateValue: [],
				loading: false,
				// form: {
				// 	id: null, //"",
				// 	relaGoodsSku: '', //"",
				// 	goodsSku: '', //"",

				// },

				// formRules: {
				// 	goodsSku: [{
				// 		required: true,
				// 		message: this.$t('FormMsg.Please_Enter'),
				// 		trigger: 'blur'
				// 	}],
				// 	relaGoodsSku: [{
				// 		required: true,
				// 		message: this.$t('FormMsg.Please_Enter'),
				// 		trigger: 'blur'
				// 	}],
				// },
				loading_load: false,
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				selectOption: {
					// wh_weight_unit: [],
					// wh_vol_unit: [],
					// wh_goods_fee_type:[],
					// wh_size_unit: [],
					// statusList: WSkuInfo_status
				},
				//查询，排序方式
				filterData: {
					orderBy: 'goods_sku', //排序字段
					sortAsc: 'N', //desc降序，asc升序

					agentUser: '',
					// "accountPeriod":"",
					// "billNo":""
					status: '',
					// putWhNo: '',
					// goodsSku: '',
					// sysGoodsSku:"",
					// hashCode: '',
					// goodsName: '',
					// goodsNameEn: '',
					keyword: ''
					// declareNameCh: '',
				}
			};
		},

		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			if (!!this.status) {
				this.filterData.status = this.status;
			}
			this.initData();
		},
		methods: {
			initData() {

				this.pagination.current_page = 1;
				// this.currentSel = {};
				this.multipleSelection = [];
				this.getPageData();
				// this.getDicData();
			},
			// i18nFormatter(value) {
			// 	return i18nStatus(value, this.selectOption.statusList)
			// },
			changeAgentUser(val) {
				this.filterData.agentUser = val;
				this.initData();
			},

			exportExcelAction() {
				let columns = [{
						title: 'SKU',
						key: 'goodsSku'
					},
					{
						title: this.$t('i18nn_9168144190f66f5d'),
						key: 'relaGoodsSku',
					},
					// {
					// 	title: this.$t('i18nn_15b3627faddccb1d'),
					// 	key: 'remark'
					// },
				];

				let Data = this.tableData;

				// console.log('Data', Data);
				// return;
				this.expExcelData = Data;
				this.excelHead = columns;
				this.excelName = 'wh_sku_relation';
				this.excelOption = {};
				this.excelOpenTime = new Date().getTime();
				// excelUtilsNew.exportExcel(columns, Data, 'wh_sku');
			},
			// handleSelectStatus(key, keyPath) {
			// 	// console.log(key, keyPath);
			// 	this.filterData.status = key;
			// 	this.initData();
			// },
			//跳转页面
			// toPageUrl(name){
			//   this.$router.push({'name':name});
			// },
			//打开新增编辑，弹窗
			openDioalog(row) {
				// this.addOpenRow = row;
				// this.addOpenTime = new Date().getTime();
				// return;
				// if (!row) {
					this.$router.push({
						name: "WSkuRelationAdd"
					})
				// } else {
				// 	this.$router.push({
				// 		name: "WSkuRelationAdd",
				// 		query: {
				// 			id: row.id
				// 		}
				// 	})
				// }

				// console.log(formParm);
				// this.dialogFormVisible = true;
				// let form = Object.assign({}, formParm);
				// console.log('form', form);
				// // // 重置
				// this.resetForm('form');
				// if (null === formParm) {
				// 	//新增
				// 	this.dialogFormStatus = 0;
				// 	//浅拷贝、对象属性的合并
				// 	this.form = form;
				// } else {
				// 	//修改
				// 	this.dialogFormStatus = 1;
				// 	//浅拷贝、对象属性的合并
				// 	this.form = form;
				// }
			},
			//打开编辑
			// openEdit(event, row, index) {
			// 	event.stopPropagation();
			// 	this.openDioalog(row);
			// },

			//多选
			handleSelectionChange(val) {
				console.log(val);
				this.multipleSelection = val;
			},

			//删除
			// delAction(event, row) {
			// 	this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {
			// 			type: 'warning'
			// 		})
			// 		.then(() => {
			// 			this.delDataAction(event, row);
			// 		})
			// 		.catch(() => {});
			// },
			// //删除
			// delDataAction(event, row) {
			// 	event.stopPropagation();
			// 	console.log('delDataAction', row);
			// 	this.postData(this.$urlConfig.WhSkuRelationDel + '/' + row.id, {}, 'delete', () => {});
			// },

			delAction(event, row) {
				event.stopPropagation();
				let parm = [];
				if (row) {
					//单条
					parm = [row.id];
				} else {
					//多条
					let dataList = this.multipleSelection;
					if (dataList.length < 1) {
						this.$message.warning(this.$t('Storage.DropShipping.sel_tips_1'));
						return;
					}
					let dataListParm = dataList.map(v => v.id);
					parm = dataListParm;
				}
				this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {
						type: 'warning'
					})
					.then(() => {
						// this.delDataAction(event, row);
						// let ids = [row.id];
						this.postData(this.$urlConfig.WhSkuRelationDel, {
							"ids": parm
						}, '',()=>{});
					})
					.catch(() => {});
			},

			//提交信息
			// submitForm(formName) {
			// 	this.$refs[formName].validate(valid => {
			// 		if (valid) {
			// 			let formData = Object.assign({}, this.form);
			// 			//浅拷贝、对象属性的合并
			// 			if (0 === this.dialogFormStatus) {
			// 				formData.id = null;

			// 				this.postData(this.$urlConfig.WhSkuRelationEdit, formData, '', () => {
			// 					// this.$router.push({
			// 					// 	name: "WSkuAddSuccessPage"
			// 					// });
			// 					// this.$message.succcess
			// 				});

			// 			} else {

			// 				this.postData(this.$urlConfig.WhSkuRelationAdd, formData, '', () => {
			// 					// this.$router.push({
			// 					// 	name: "WSkuAddSuccessPage"
			// 					// });
			// 				});

			// 			}
			// 		} else {
			// 			console.log('error submit!!');
			// 			this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
			// 				type: 'warning'
			// 				//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 			});
			// 			return false;
			// 		}
			// 	});
			// },
			//重置输入框
			// resetForm(formName) {
			// 	console.log(formName);
			// 	if (this.$refs[formName]) {
			// 		this.$refs[formName].resetFields();
			// 	} else {
			// 		console.log('this.$refs[formName]', this.$refs[formName]);
			// 	}
			// },

			//提交信息
			postData(url, formData, type, callback) {
				// let _this = this;
				this.loading = true;
				this.loading_load = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				HttpType.then(({
					data
				}) => {
					console.log(data);
					this.loading = false;
					this.loading_load = false;
					if (200 == data.code) {
						// this.dialogFormVisible = false;
						this.getPageData();
						if ('delete' == type) {
							this.$message({
								type: 'success',
								message: this.$t('tips.successfullyDelete')
							});
						} else {
							this.$message.success(this.$t('tips.submitSuccess'));
						}
						callback();
					} else {
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
					});
				});
			},

			//排序改变
			sortChange({
				column,
				prop,
				order
			}) {
				console.log('sortChange', {
					column,
					prop,
					order
				});
				let sortData = FormatTableSort({
					column,
					prop,
					order
				});
				this.filterData.orderBy = sortData.orderBy;
				this.filterData.sortAsc = sortData.sortAsc;
				this.getPageData();
			},

			//分页的筛选项数据
			pageFilterData() {
				return {
					"sortAsc": this.filterData.sortAsc,
					"orderBy": this.filterData.orderBy,

					proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
					status: this.filterData.status ? this.filterData.status : null,
					// goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,
					keyword: this.filterData.keyword ? this.filterData.keyword : null
				};
			},
			//请求分页数据
			getPageData() {
				let filterData = Object.assign({
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size //当前页显示数目
					},
					this.pageFilterData()
				);

				// let _this = this;
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhSkuRelationPage, filterData)
					.then(({
						data
					}) => {
						console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);
						
						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},

			//查询数据字典
			// getDicData() {
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_size_unit'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				this.selectOption.wh_size_unit = data.data['wh_size_unit'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.submitError');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.submitRequestError'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	/deep/ .el-input-number,
	.el-select {

		// width: 100px;
		.el-input__inner {
			text-align: left;
		}
	}

	.form_msg {
		color: #e6a23c;
		line-height: 150%;
	}
</style>
